@media (min-width: 1920px) and (max-width: 1925px) {
  body::after {
    width: 600px !important;
  }
  .j7a04e {
    right: 28% !important;
  }
  .j7a04e {
    top: 55% !important;
  }
}
@media screen and (min-width: 1920px) {
  body::after {
    width: 600px !important;
  }
  .j7a04e {
    right: 28% !important;
  }
  .j7a04e {
    top: 55% !important;
  }
}
@media screen and (min-width: 2560px) {
  body::after {
    width: 740px !important;
  }
  .j7a04e {
    right: 28% !important;
  }
  .j7a04e {
    top: 55% !important;
  }
}
@media (min-width: 1680px) and (max-width: 1681px) {
  body::after {
    width: 600px !important;
  }
  .j7a04e {
    right: 28% !important;
  }
  .j7a04e {
    top: 55% !important;
  }
}
@media (min-width: 1366px) and (max-width: 1367px) {
  body::after, body::before {
    width: 260px !important;
  }
  .pu860y img {
    width: 230px !important;
  }
  body::after, body::before {
    background-size: contain !important;
  }
  body::before {
    left: 20px !important;
  }
  body::before {
    bottom: 0 !important;
  }
  .j7a04e {
    right: 21% !important;
  }
  .j7a04e {
    top: 50% !important;
  }
  .e6x1b0 img {
    width: 140px !important;
  }
  .w5351i {
    max-width: 500px !important;
  }
  .w5351i {
    height: 281px !important;
  }
  .k11s38 {
    bottom: 20px !important;
  }
  .k11s38 img {
    width: 240px !important;
  }
  .k11s38 img {
    height: auto;
  }
  .b179f6 {
    height: calc(100vh - 30px) !important;
  }
  .dz75x6 {
    left: 130px !important;
  }
  .dz75x6 {
    bottom: 80px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1281px) {
  body::after, body::before {
    width: 260px !important;
  }
  .pu860y img {
    width: 250px !important;
  }
  body::after, body::before {
    background-size: contain !important;
  }
  body::before {
    left: 20px !important;
  }
  body::before {
    bottom: 0 !important;
  }
  .j7a04e {
    right: 21% !important;
  }
  .j7a04e {
    top: 50% !important;
  }
  .e6x1b0 img {
    width: 140px !important;
  }
  .w5351i {
    max-width: 450px !important;
  }
  .w5351i {
    height: 250px !important;
  }
  .k11s38 {
    bottom: 20px !important;
  }
  .k11s38 img {
    width: 225px !important;
  }
  .k11s38 img {
    height: auto;
  }
  .b179f6 {
    height: calc(100vh - 30px) !important;
  }
  .dz75x6 {
    left: 170px !important;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  body::after, body::before {
    width: 200px !important;
  }
  .pu860y img {
    width: 230px !important;
  }
  body::after, body::before {
    background-size: contain !important;
  }
  body::before {
    left: 20px !important;
  }
  body::before {
    bottom: 0 !important;
  }
  .s9sa85, .dz75x6, .j7a04e {
    display: none !important;
  }
  .e6x1b0 img {
    width: 115px !important;
  }
  .w5351i {
    max-width: 400px !important;
  }
  .w5351i {
    height: 225px !important;
  }
  .k11s38 {
    bottom: 20px !important;
  }
  .k11s38 img {
    width: 200px !important;
  }
  .k11s38 img {
    height: auto;
  }
  .b179f6 {
    height: calc(100vh - 30px) !important;
  }
}
@media (min-width: 801px) and (max-width: 991.2px) {
  body::after, body::before {
    width: 200px !important;
  }
  .pu860y img {
    width: 230px !important;
  }
  body::after, body::before {
    background-size: contain !important;
  }
  body::before {
    left: 20px !important;
  }
  body::before {
    bottom: 0 !important;
  }
  .e6x1b0 img {
    width: 120px !important;
  }
  .w5351i {
    max-width: 450px !important;
  }
  .w5351i {
    height: 253px !important;
  }
  .k11s38 {
    bottom: 20px !important;
  }
  .k11s38 img {
    width: 230px !important;
  }
  .k11s38 img {
    height: auto;
  }
  .b179f6 {
    height: calc(100vh - 30px) !important;
  }
  .dz75x6 {
    left: 65px !important;
  }
  .j7a04e {
    right: 130px !important;
  }
  .j7a04e {
    top: 0px !important;
  }
  .pu860y {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 800px) {
  body::after, body::before {
    width: 200px !important;
  }
  .pu860y img {
    width: 230px !important;
  }
  body::after, body::before {
    background-size: contain !important;
  }
  body::before {
    left: 20px !important;
  }
  body::before {
    bottom: 0 !important;
  }
  .e6x1b0 img {
    width: 135px !important;
  }
  .w5351i {
    max-width: 450px !important;
  }
  .w5351i {
    height: 253px !important;
  }
  .k11s38 {
    bottom: 20px !important;
  }
  .k11s38 img {
    width: 230px !important;
  }
  .k11s38 img {
    height: auto;
  }
  .b179f6 {
    height: calc(100vh - 30px) !important;
  }
  .dz75x6 {
    left: 0 !important;
  }
  .dz75x6 {
    bottom: 150px !important;
  }
  .j7a04e {
    right: 15px !important;
  }
  .j7a04e {
    top: 0px !important;
  }
  body::after {
    position: absolute !important;
  }
  body::after {
    right: 0 !important;
  }
  .dz75x6 .um863l {
    display: flex;
  }
  .dz75x6 .um863l {
    width: 100% !important;
  }
  .dz75x6 .um863l {
    justify-content: center;
  }
  .dz75x6 .um863l {
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  body::after, body::before {
    display: none !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 767px) {
  .k11s38 img {
    width: 210px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .j7a04e {
    right: 25px !important;
  }
  .j7a04e {
    top: 0 !important;
  }
  .s9sa85 img, .j7a04e img, .dz75x6 img {
    width: 70px;
  }
  .dz75x6 .um863l {
    display: flex;
  }
  .dz75x6 .um863l {
    width: 100% !important;
  }
  .dz75x6 .um863l {
    justify-content: center;
  }
  .dz75x6 .um863l {
    align-items: center;
  }
  .pu860y img {
    width: 250px !important;
  }
  .dz75x6 {
    left: 0 !important;
  }
  .dz75x6 {
    bottom: 150px !important;
  }
}
@media screen and (min-width: 768px) and (max-height: 360px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6, .pu860y {
    display: none !important;
  }
  .w5351i {
    height: 175px !important;
  }
  .w5351i {
    max-width: 310px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 30px !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 599px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 120px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 197px !important;
  }
  .w5351i {
    max-width: 350px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 0px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y {
    display: none !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 499px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 120px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 197px !important;
  }
  .w5351i {
    max-width: 350px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 0px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
}
@media screen and (min-width: 416px) and (max-width: 479px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 197px !important;
  }
  .w5351i {
    max-width: 350px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 30px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
}
@media screen and (min-width: 413px) and (max-width: 415px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 197px !important;
  }
  .w5351i {
    max-width: 350px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 0px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
}
@media screen and (min-width: 405px) and (max-width: 412px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 197px !important;
  }
  .w5351i {
    max-width: 350px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 0px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
}
@media screen and (min-width: 395px) and (max-width: 404px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 189px !important;
  }
  .w5351i {
    max-width: 336px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y img {
    width: 220px !important;
  }
}
@media screen and (min-width: 392px) and (max-width: 394px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 189px !important;
  }
  .w5351i {
    max-width: 336px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y img {
    width: 220px !important;
  }
}
@media screen and (min-width: 390px) and (max-width: 391px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 189px !important;
  }
  .w5351i {
    max-width: 336px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y img {
    width: 220px !important;
  }
  .pu860y {
    display: none !important;
  }
}
@media screen and (min-width: 381px) and (max-width: 389px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 189px !important;
  }
  .w5351i {
    max-width: 336px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y img {
    width: 220px !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 380px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 189px !important;
  }
  .w5351i {
    max-width: 336px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y img {
    width: 220px !important;
  }
}
@media screen and (min-width: 359px) and (max-width: 374px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 189px !important;
  }
  .w5351i {
    max-width: 336px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 60px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
}
@media screen and (min-width: 321px) and (max-width: 358px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 163px !important;
  }
  .w5351i {
    max-width: 290px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 40px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y img {
    width: 210px !important;
  }
  .pu860y {
    margin: 4px 0 0 0 !important;
  }
  .pu860y {
    display: none !important;
  }
}
@media screen and (max-width: 320px) {
  body::after, body::before {
    display: none !important;
  }
  .e6x1b0 img {
    width: 100px !important;
  }
  .dz75x6 {
    display: none !important;
  }
  .w5351i {
    height: 163px !important;
  }
  .w5351i {
    max-width: 290px !important;
  }
  .b179f6 {
    height: calc(100vh - 55px) !important;
  }
  .k11s38 img {
    width: 180px !important;
  }
  .s9sa85 img, .j7a04e img {
    width: 40px;
  }
  .j7a04e {
    top: 0 !important;
  }
  .j7a04e {
    right: 5px !important;
  }
  .k11s38 img {
    width: 170px !important;
  }
  .k11s38 img {
    height: auto !important;
  }
  .pu860y img {
    width: 210px !important;
  }
  .pu860y {
    margin: 4px 0 0 0 !important;
  }
}/*# sourceMappingURL=Responsive.css.map */